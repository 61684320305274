import React, { Component } from "react";
// import PropTypes from "prop-types";
import ImageAuto from "../../utilities/imageAuto/imageAuto.js";
import ProjectStatusTag from "../../utilities/projectStatusTag/projectStatusTag.js";
import GridItemButton from "../gridItemButton/gridItemButton.js";
// import InformationalTag from "../../utilities/informationalTag/informationalTag";
import "./grid.sass";
import {
  toUrlImg,
  calculatePriceHigh,
  returnItemStatus,
  showPriceAsUsd,
  canUseDOM,
} from "../../../helpers";
import { Link } from "react-router-dom";

class Grid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoButtonSnap: false,
      gridSnap: false,
    };
  }
  componentWillMount() {
    this.styleItemGrid();
  }
  componentDidMount() {
    this.styleItemGrid();
    window.addEventListener("resize", () => this.styleItemGrid());
    // console.log(
    //   "from grid",
    //   this.grid.getBoundingClientRect().top + window.pageYOffset
    // );
    // window.pageYOffset + el.getBoundingClientRect().top
  }
  styleItemGrid = () => {
    //set the fixed top position of the mobile filter toggle
    if (canUseDOM) {
      if (this.grid) {
        this.props.setTop(
          this.grid.getBoundingClientRect().top + window.pageYOffset
        );
      }
      const width = window.innerWidth;
      if (width <= 850 && !this.state.infoButtonSnap) {
        this.setState({ infoButtonSnap: true });
      } else if (width >= 850 && this.state.infoButtonSnap) {
        this.setState({ infoButtonSnap: false });
      } else if (width <= 780 && !this.state.gridSnap) {
        this.setState({ gridSnap: true });
      } else if (width >= 780 && this.state.gridSnap) {
        this.setState({ gridSnap: false });
      } else {
        return;
      }
    }
  };
  componentWillUnmount() {
    if (window !== undefined)
      window.removeEventListener("resize", () => this.styleItemGrid());
  }
  // componentDidUpdate(prevProps) {
  //   if (prevProps.filter !== this.props.filter) {
  //     console.log("from the grid", this.props.filter);
  //   }
  // }
  makeDescriptions = (project) => {
    switch (project.type) {
      case "keycap Set":
        return `${project.profile} Keycap Set`;
      case "case":
        return `${project.material} Case`;
      case "Pcb":
        return "Kit";
      default:
        return project.type;
    }
  };
  search = (items) => {
    // todo maybe change this and see if we want to do it differently this way will trigger on all sorts of things like names and designers and shit
    if (this.props.filter.length > 0) {
      return items.filter((obj) => {
        const objStr = JSON.stringify(obj);
        let match = false;
        this.props.filter.forEach((term) => {
          if (objStr.indexOf(term) != -1) {
            match = true;
          }
        });
        return match;
      });
    } else {
      return items;
    }
  };
  sortBy = (items) => {
    // todo test this
    switch (this.props.sorts) {
      case "Most":
        return [...this.search(items)].sort(
          (a, b) => a.submittedForm.length - b.submittedForm.length
        );
      case "Least":
        return [...this.search(items)]
          .sort((a, b) => a.submittedForm.length - b.submittedForm.length)
          .reverse();
      case "New to Old":
        const foo = [...this.search(items)].reverse();
        // console.log("this is foo", foo);
        return foo;
      default:
        return this.search(items);
    }
  };
  returnPrice = (product) => {
    if (
      product.otherProductsNeededToBuild &&
      product.otherProductsNeededToBuild.length > 0 && !product.isGroupBuy
    ) {
      return (
        <h5 className="price">
          {`${showPriceAsUsd(product.price)}`}
          <span> - </span>
          {`${calculatePriceHigh(product)}`}
        </h5>
      );
    } else if (product.priceHigh > 0) {
      return (
        <h5 className="price">
          {`${showPriceAsUsd(product.price)}`} <span>- </span>
          {`${showPriceAsUsd(product.priceHigh)}`}
        </h5>
      );
    } else {
      return <h5>{`${showPriceAsUsd(product.price)}`}</h5>;
    }
  };
  renderPrices = (product) => {
    if (product.price) {
      return this.returnPrice(product);
    }
  };
  buildLink = (item) => {
    if (this.props.isStore) {
      return `store/${item._id}`;
    } else {
      return `projects/${item._id}`;
    }
  };
  handleSplitProductsByCategory = () => {
    if (this.props.items.every((item) => Array.isArray(item))) {
      const itemsToReturn = this.props.items
        .map((arrayOfItems) => this.sortBy(arrayOfItems)).flat()
      return itemsToReturn
    } else {
      return this.sortBy(this.props.items);
    }
  };
  render() {
    return (
      <div className="grid" ref={(div) => (this.grid = div)}>
        {this.handleSplitProductsByCategory().map((i) => {
          const format = returnItemStatus(i);
          return (
            <div className="grid__item" key={i._id}>
              <div className="grid__item__image">
                <Link to={this.buildLink(i)}>
                  {i.mainImage && i.mainImage.square ? (
                    <ImageAuto
                      width={300}
                      image={toUrlImg(i.mainImage.square)}
                      alt={i.name}
                    />
                  ) : (
                    ""
                  )}
                </Link>
              </div>

              <div className="grid__item__info">
                <h4>{i.name}</h4>
                <p>{this.makeDescriptions(i)}</p>
                <span className="grid-price-wrapper ">
                  {this.renderPrices(i)}
                  {/* {!this.state.infoButtonSnap && <InformationalTag product={i} />} */}
                  {this.state.gridSnap && (
                    <ProjectStatusTag projectStatus={format} small />
                  )}
                </span>
                {/* <h5 className="price">{this.renderPrices(i)}</h5> */}

                <div className="grid__item__info__bottom">
                  <GridItemButton
                    item={i}
                    projectStatus={i.projectFormat}
                    kit={i.kit}
                  />
                  {!this.state.gridSnap && (
                    <div className="grid__item__info__bottom__btn">
                      <ProjectStatusTag projectStatus={format} small={true} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}


export default Grid;
