import React, { useEffect } from "react";
import { connect } from "react-redux";

import StoreFeatured from "./storeFeatured/storeFeatured";
import ItemGrid from "../itemGrid/itemGrid";

import { StateType } from "../../../common/reducers/index";
import { getProducts, getProduct } from "../../reducers/products";
import { Link } from "react-router-dom";
import {
  truncateString,
  toUrlImg,
  returnItemStatus,
  isTypeOfProduct,
} from "../../helpers";
import { Helmet } from "react-helmet";
import "./styles/store.sass";
import { getConfig } from "../../reducers/siteConfig";
import Loading from "../utilities/loading/loading";
import FeaturedItem from "./storeFeatured/featuredItem";
import Jumbotron from "../jumbotron/jumbotron";
import { IProduct } from "../../../server/models/products";
import { ISiteConfig } from "../../../server/models/siteConfig";
type StoreProps = {
  products: IProduct[];
  loading: boolean;
  config: ISiteConfig;
  configLoading: boolean;
  getProducts: () => void;
  getConfig: (overRide: boolean) => void;
};

const Store = (props: StoreProps) => {
  useEffect(() => {
    if (!props.loading && props.products.length === 0) {
      props.getProducts();
    }
    if (!props.configLoading && !props.config) {
      props.getConfig(false);
    }
  });

  const keyBoardsToTheTop = () => {
    return [
      props.products.filter((product) => product.type === "Pcb"),
      props.products.filter((product) => product.type === "Case"),
      props.products.filter(
        (product) => product.type !== "Pcb" && product.type !== "Case"
      ),
    ];
  };
  if (props.products.length === 0 || props.config === null) {
    return <Loading></Loading>;
  } else {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Boardsource - Store</title>
          <meta
            name="description"
            content="View all keyboards in stock on the Boardsource store."
          />
          <meta
            name="keywords"
            content="Boardsource, Keyboards, Boards, Custom Keyboards"
          />
          <meta name="author" content="Boardsource" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          {/*  <link rel="canonical" href={window.localStorage.href} />

               <meta property="og:url" content={window.localStorage.href} />*/}

          <meta property="og:title" content="Boardsource - Store" />
          <meta
            property="og:description"
            content="View all in stock keyboards in the Boardsource store."
          />
          <meta
            property="og:image"
            content={toUrlImg(
              isTypeOfProduct(props.config.products.main)
                ? props.config.products.main.mainImage.rectangle
                : ""
            )}
          />
        </Helmet>

        <div className="store page">
          <Link
            to={`/store/${
              isTypeOfProduct(props.config.products.main)
                ? props.config.products.main._id
                : null
            }`}
          >
            <Jumbotron
              item={props.config.products.main}
              // todo when posting this get the users user name and pump it into the owner field
              name={
                isTypeOfProduct(props.config.products.main)
                  ? props.config.products.main.name
                  : "missing"
              }
              creator={"Boardsource"}
              kit={
                isTypeOfProduct(props.config.products.main)
                  ? props.config.products.main.otherProductsNeededToBuild
                    ? props.config.products.main.otherProductsNeededToBuild
                        .length > 0
                    : false
                  : false
              }
              tagline={truncateString(
                isTypeOfProduct(props.config.products.main)
                  ? props.config.products.main.description
                  : "",
                50
              )}
              accentColor={
                isTypeOfProduct(props.config.products.main)
                  ? props.config.products.main.projectColorFamily
                      .projectAccentColor
                  : null
              }
              image={
                isTypeOfProduct(props.config.products.main)
                  ? props.config.products.main.mainImage.rectangle
                  : ""
              }
              altImages={
                isTypeOfProduct(props.config.products.main)
                  ? props.config.products.main.additionalImages
                  : []
              }
              productType={
                isTypeOfProduct(props.config.products.main)
                  ? props.config.products.main.type
                  : "Group Buy"
              }
              projectStatus={returnItemStatus(
                isTypeOfProduct(props.config.products.main)
                  ? props.config.products.main
                  : null
              )}
              onClick={() => {
                //history.push(`/store/${props.config.products.main._id}`);
              }}
            />
          </Link>

          {/* <div className="spacer content" /> */}
          <h3 className="info-title store top-space">Featured Items</h3>
          <StoreFeatured>
            <FeaturedItem product={props.config.products.featured[0]} />
            <FeaturedItem product={props.config.products.featured[1]} />
            {/* {this.props.products.length > 0 ? (
              <>
                <FeaturedItem
                  product={this.props.products.find(
                    p => p._id === "5e7eeda817ae0405f90b0ba5"
                  )}
                />
                <FeaturedItem
                  product={this.props.products.find(
                    p => p._id === "5e7edea117ae0405f90b0b95"
                  )}
                />
              </>
            ) : (
              ""
            )} */}
          </StoreFeatured>

          {/* <div className="spacer content" /> */}
          <div className="product-grid">
            <h3 className="info-title store">All Boardsource Products</h3>

            <ItemGrid isStore={true} items={keyBoardsToTheTop()} />
          </div>
        </div>
      </>
    );
  }
};

const mapStateToProps = (state: StateType) => {
  return {
    products: state.product.products.filter(
      (product) => product.main === true && !product.hidden
    ),
    loading: state.product.loading,
    config: state.config.config,
    configLoading: state.config.loading,
  };
};

export default connect(mapStateToProps, {
  getConfig,
  getProducts,
})(Store);
