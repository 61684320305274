import React, { Component } from "react";
import ProjectStatusTag from "../../utilities/projectStatusTag/projectStatusTag.js";
import {
  calculatePriceHigh,
  returnItemStatus,
  showPriceAsUsd,
  isItemAProduct,
} from "../../../helpers";

import "./jumbotronInfo.sass";
import { Timestamp } from "mongodb";

class JumbotronInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  renderPrice = () => {
    if (returnItemStatus(this.props.item) === "In Stock") {
      if (
        this.props.item.otherProductsNeededToBuild &&
        this.props.item.otherProductsNeededToBuild.length > 0
      ) {
        // todo go over this logic here to make sure it is doing what we want it to do based on product/project status
        return (
          <h3 className="price">
            {showPriceAsUsd(this.props.item.price)} {this.props.item && !this.props.item.isGroupBuy ? ` - ${calculatePriceHigh(this.props.item)}` : ""}
          </h3>
        );
      } else {
        return (
          <h3 className="price">{showPriceAsUsd(this.props.item.price)}</h3>
        );
      }
    } else if (returnItemStatus(this.props.item) === "Group Buy") {
      if (this.props.item.price && this.props.item.priceHigh) {
        return (
          <h3 className="price">
            {showPriceAsUsd(this.props.item.price)} -{" "}
            {showPriceAsUsd(this.props.item.priceHigh)}
          </h3>
        );
      } else {
        return <h3 className="price">${this.props.item.price}</h3>;
      }
    }
  };
  renderBy = () => {
    if (isItemAProduct(this.props.item)) {
      if (
        this.props.productType === "Misc Part" ||
        !this.props.item.proprietary
      ) {
        return (
          <h3 className="jubbo-hide-at">{`${this.props.productType} Sold By ${this.props.creator}`}</h3>
        );
      } else {
        return (
          <h3 className="jumbo_info_by">{`${this.props.productType} by ${this.props.creator}`}</h3>
        );
      }
    } else {
      return (
        <h3 className="jumbo_info_by">{`${this.props.productType} by ${this.props.creator}`}</h3>
      );
    }
  };

  render() {
    return (
      <div className="jumbotron__info__container">
        <h2>{this.props.name}</h2>
        <ProjectStatusTag projectStatus={this.props.projectStatus} />
        {/* {this.renderBy()} */}
        {/* {!this.props.descSnap && } */}
        {this.renderPrice(this.props)}
      </div>
    );
  }
}

export default JumbotronInfo;
