import React, { Component } from "react";
import PropTypes from "prop-types";
import JumbotronInfo from "./jumbotronInfo/jumbotronInfo.js";
import JumbotronImage from "./jumbotronImage/jumbotronImage.js";
import JumbotronButton from "./jumbotronButton/jumbotronButton.js";
import IdealImage from "react-ideal-image";
import Modal from "../utilities/modal/modal";

import "./jumbotron.sass";

class Jumbotron extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageHeight: null,
      descSnap: false,
      modalOpen: false,
    };
    this.image = React.createRef();
    this.container = React.createRef();
    this.info = React.createRef();
  }
  componentDidMount() {
    // this.setImageHeight();
    this.styleJumboHeight(window.innerWidth);
    this.returnInfoStyles(window.innerWidth);
    // console.log("window width:", window.innerWidth);
    window.addEventListener("resize", () =>
      this.styleJumboHeight(window.innerWidth)
    );
    // console.log("heyyyy", this.info);
  }
  styleJumboHeight = (width) => {
    this.styleJumboInfo();
    // console.log("from buggy jumbo", this.state.imageHeight, width);
    if (this.image.current !== null || this.container.current !== null) {
      this.setState({ imageHeight: `${this.image.current.offsetHeight}px` });
      if (width < 520) {
        // console.log(this.container);
        // console.log(this.image);
        this.container.current.style.height = `${this.image.current.offsetHeight}px`;
        // console.log(this.image);
      } //maybe add an else here to set back to auto/null etc for jumbo height, currently the width is a diff size than the window innerWidth due to stuff breaking out
    }
    if (width > 1280 && this.state.imageHeight !== null) {
      this.setState({ imageHeight: null });
    }
  };
  styleJumboInfo = () => {
    const width = window.innerWidth;
    if (width <= 800) {
      this.setState({ descSnap: true });
    } else if (width > 800) {
      this.setState({ descSnap: false });
    }
  };
  returnInfoStyles = (width) => {
    let styles = {};
    if (width < 520) {
      styles = {
        height: `${parseInt(this.state.imageHeight) * 0.9}px`,
        ...this.offsetInfo(),
      };
    } else {
      styles = {
        height: `auto`,
        ...this.offsetInfo(),
      };
      // styles = {
      //   height: `${parseInt(this.state.imageHeight)}px`,
      //   ...this.offsetInfo(),
      // };
    }
    return styles;
  };
  offsetInfo = () => {
    // setTimeout(() => {
    if (this.info.current !== null) {
      return { left: `${this.info.current.offsetTop}px` };
    } else {
      return;
    }
    // }, 1000);
  };

  // setWindowSizes = () => {
  //   //resize all the time
  //   this.setImageHeight();
  //   // if(window.innerWidth <
  //   // console.log(window.innerWidth);
  // };
  // setImageHeight = () => {
  //   this.setState({
  //     imageHeight: this.image.current.getBoundingClientRect().height
  //   });
  // };
  jumboToggleModal = () => {
    // console.log("from button modal", this.state.open);
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };
  render() {
    const duration = 300;
    const defaultStyle = {
      transition: `opacity ${duration}ms ease-in-out`,
      opacity: 0,
    };

    const transitionStyles = {
      entering: { opacity: 0 },
      entered: { opacity: 1 },
      exiting: { opacity: 0 },
      exited: { opacity: 0 },
    };
    return (
      <div
        className="jumbotron"
        ref={this.container}
        style={
          this.state.imageHeight !== null
            ? {
              height: this.state.imageHeight,
            }
            : {}
        }
      >
        <div
          className="jumbotron__info"
          ref={this.info}
          style={this.returnInfoStyles()}
        >
          {/* need to set prop types */}
          <JumbotronInfo
            name={this.props.name}
            productType={this.props.productType}
            tagline={this.props.tagline}
            projectStatus={this.props.projectStatus}
            creator={this.props.creator}
            item={this.props.item}
            descSnap={this.state.descSnap}
          />
          {/* need to set prop types */}
          <JumbotronButton
            modal={this.props.modal}
            modalProp={this.props.modalProp}
            onClick={this.props.onClick}
            projectStatus={this.props.projectStatus}
            kit={this.props.kit}
            accentColor={"#" + this.props.accentColor}
            item={this.props.item}
            toggleModal={this.jumboToggleModal}
          />
        </div>
        <div className="jumbotron__image" ref={this.image}>
          {/* need to set prop types */}
          <JumbotronImage
            altImages={this.props.altImages}
            image={this.props.image}
            productType={this.props.productType}
            carouselOne={this.props.carouselOne}
            carouselTwo={this.props.carouselTwo}
            accentColor={"#" + this.props.accentColor}
          />
        </div>

        <Modal
          modalProp={this.props.modalProp}
          //make modal type full
          modalType="full"
          ModalContent={this.props.modal}
          modalColor={this.props.modalColor}
          open={this.state.modalOpen}
          toggleModal={this.jumboToggleModal}
        />
      </div>

      // <Button
      // color="purple"
      // type="modal"
      // modalType="auto"
      // ModalContent={AuthModal}
      // text={this.state.loginButtonText}
      // filled={true}
      // filled
      // />

      // {/* <img src={jumbotronTest} alt="" /> */}
      // {/* <IdealImage
      //   srcSet={[{ src: jumbotronTest, width: 1000 }]}
      //   alt="hey whats up"
      //   width={1000}
      //   height={2095}
      //   placeholder={{ color: "red" }}
      // /> */}
    );
  }
}

export default Jumbotron;

Jumbotron.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  kit: PropTypes.bool.isRequired,
  creator: PropTypes.string.isRequired,
  // projectType: PropTypes.string.isRequired,
  tagline: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  projectStatus: PropTypes.string.isRequired,
  accentColor: PropTypes.string.isRequired,
};
