import React, { Component } from "react";
import PropTypes from "prop-types";

import ImageAuto from "../../utilities/imageAuto/imageAuto";
import ClickImage from "../../utilities/clickImage/clickImage";
import ImageInfoTag from "../../utilities/imageInfoTag/imageInfoTag";
import InformationalTag from "../../utilities/informationalTag/informationalTag";
import ProjectStatusTag from "../../utilities/projectStatusTag/projectStatusTag";
import {
  toUrlImg,
  calculatePriceHigh,
  returnItemStatus,
  showPriceAsUsd,
} from "../../../helpers";

import { Link } from "react-router-dom";
import "./featuredItem.sass";

class FeaturedItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kit: true,
      tagSnap: false,
    };
  }
  componentDidMount() {
    this.styleFeaturedItem();
    window.addEventListener("resize", () => this.styleFeaturedItem());
  }
  styleFeaturedItem = () => {
    const width = window.innerWidth;

    if (width <= 520 && !this.state.tagSnap) {
      this.setState({ tagSnap: true });
    } else if (width >= 520 && this.state.tagSnap) {
      this.setState({ tagSnap: false });
    } else {
      return;
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", () => this.styleFeaturedItem());
  }
  renderPrices = (product) => {
    if (
      this.props.product.otherProductsNeededToBuild &&
      this.props.product.otherProductsNeededToBuild.length > 0
    ) {
      return (
        <h5>
          {`${showPriceAsUsd(product.price)}`} <span>- </span>
          {`${calculatePriceHigh(product)}`}
        </h5>
      );
    } else {
      return <h5>{`${showPriceAsUsd(product.price)}`}</h5>;
    }
  };
  render() {
    if (this.props.product) {
      return (
        <div className="store-featured-item">
          <Link to={`/store/${this.props.product._id}`}>
            <div className="store-featured-item__image">
              <ClickImage>
                <ImageAuto
                  image={toUrlImg(this.props.product.mainImage.rectangle)}
                  width={600}
                  alt={this.props.product.name}
                />
              </ClickImage>

              <ImageInfoTag type="new" position="bottom left" color="light" />
            </div>
          </Link>
          <div className="store-featured-item__info">
            <h4>{this.props.product.name}</h4>
            {this.state.tagSnap && (
              <ProjectStatusTag
                projectStatus={returnItemStatus(this.props.product)}
              />
            )}

            <p>{this.props.product.tagline}</p>
            <div className="store-featured-item__info__bottom">
              {this.renderPrices(this.props.product)}
              <div className="store-featured-item__info__bottom__status">
                {/* <InformationalTag product={this.props.product} /> */}
                <span className="tag-separator">
                  {!this.state.tagSnap && (
                    <ProjectStatusTag
                      projectStatus={returnItemStatus(this.props.product)}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }
}

FeaturedItem.propTypes = {
  product: PropTypes.object.isRequired,
};

export default FeaturedItem;
