import React, { Component } from "react";

import { addItemToCart } from "../../../reducers/cart";
import cartIcon from "../../../images/icons/cart.svg";
// import rightArrow from "../../../images/icons/arrow-right-dark.svg";
import check from "../../../images/icons/check_mark.svg";
import { connect } from "react-redux";
import { returnItemStatus } from "../../../helpers";
import { toast } from "react-toastify";
import "./jumbotronButton.sass";
// import Button from "../../utilities/buttons/button";
// aadd boardsource gb button work same as instock
class JumbotronButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      kit: true,
      mainWidthBuy: 50,
      // open: true,
      itemStatus: "",
    };
  }
  componentDidMount() {
    this.setState({ itemStatus: returnItemStatus(this.props.item) });
  }
  renderButtonMainText = (props) => {
    //NEEDS TO BE HOOKED UP IN STOCK
    // if (this.state.quantity > 0) {
    //   return "Buy Now";
    // }
    if (props.kit) {
      if (
        this.state.itemStatus === "In Stock" ||
        this.state.itemStatus === "Group Buy"
      ) {
        return "Configure";
      } else {
        return "Out of stock";
      }
    } else {
      switch (this.state.itemStatus) {
        case "Interest Check":
          return "Follow";
        case "In Stock":
          return "Buy Now";
        case "Just Ran Out":
          return "Out of Stock";
        case "Group Buy":
          return "Enter";
        default:
          return "Join";
      }
    }
  };
  renderAltButtonText = () => {
    if (this.state.itemStatus === "Just Ran Out") {
      return "More on the way";
    } else {
      // console.log(
      //   "here",
      //   this.props.kit,
      //   this.props.item.otherProductsNeededToBuild.length > 0,
      //   this.state.itemStatus
      // );
      if (this.props.kit) {
        if (this.props.item.otherProductsNeededToBuild.length > 0) {
          return "Buy Kit";
        } else {
          return "Pick Options";
        }
      } else {
        return "Add to Cart";
      }
    }
  };
  renderButton = (props) => {
    if (this.props.item.hasOwnProperty("quantityInStock")) {
      return (
        <div className="jumbotron__info__button__background">
          {this.renderButtonOverlay()}
          <button
            className="jumbotron__info__button__background__img"
            // style={{ width: `${100 - this.state.mainWidthBuy}%` }}
          >
            <h5>{this.renderAltButtonText()}</h5>
            {this.state.itemStatus === "In Stock" ||
            this.state.itemStatus === "Group Buy" ? (
              <img
                src={cartIcon}
                alt="shopping cart"
                className={`${this.state.mainHovered ? "hovered" : ""}`}
              />
            ) : (
              ""
            )}
          </button>
        </div>
      );
    } else {
      return (
        <div className="jumbotron__info__button__background">
          {this.renderButtonOverlay()}
          <button
            className="jumbotron__info__button__background__img"
            // style={{ width: `${100 - this.state.mainWidthBuy}%` }}
          >
            <h5>{this.generateNonProductText(this.props.item)}</h5>

            <img
              // src={this.generateNonProductIcon(this.props.item)}
              src={check}
              alt="checkmark"
              className={`${
                this.state.mainHovered ? "hovered" : ""
              } non-product`}
            />
          </button>
        </div>
      );

      // return this.renderButtonOverlay();
    }
  };
  generateNonProductText = (project) => {
    if (project.projectFormat === "Interest Check" && project.form.length > 0) {
      return "Answer Form";
    } else if (
      project.projectFormat === "Interest Check" &&
      project.form.length === 0
    ) {
      return "Stay Updated";
    } else if (project.projectFormat === "Group Buy") {
      return "Follow Project";
    }
  };
  // generateNonProductIcon = (project) => {
  //   if()
  // }
  // returnButtonMainWidth = (project) => {
  //   //NEEDS TO BE HOOKED UP
  //   if (
  //     this.props.item.quantityInStock &&
  //     this.props.item.quantityInStock > 0
  //   ) {
  //     const mainWidth = 60;
  //     // this.styleUnderlayText(mainWidth);
  //     this.setState({ mainWidth });
  //     return `${mainWidth}%`;
  //   }
  // };

  handleJumboButtonClick = (props) => {
    if (props.kit && props.onClick) {
      this.props.onClick();
    } else if (props.kit) {
      if (
        this.state.itemStatus === "In Stock" ||
        this.state.itemStatus === "Group Buy"
      ) {
        this.props.toggleModal();
      } else {
        toast.info(
          `This item is out of stock we are working on getting more.`,
          {
            position: "top-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      switch (this.state.itemStatus) {
        case "Interest Check":
          return this.props.onClick ? this.props.onClick() : "";
        case "In Stock":
          if (props.item.quantityInStock > 0) {
            return this.props.addItemToCart({
              id: this.props.item._id,
              qty: 1,
            });
          } else {
            return toast.info(
              `This item is out of stock we are working on getting more.`,
              {
                position: "top-center",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          }

        case "Group Buy":
          return this.props.onClick ? this.props.onClick() : "";
        default:
          return this.props.onClick ? this.props.onClick() : "";
      }
    }
  };
  renderButtonOverlay = () => {
    if (this.state.kit) {
      return (
        <button
          className={`jumbotron__info__button__text ${
            this.state.mainHovered ? "hovered" : ""
          }`}
          style={{
            backgroundColor: this.props.accentColor,
            // width: `${this.state.mainWidthBuy}%`,
          }}
        >
          <h4>{this.renderButtonMainText(this.props)}</h4>
        </button>
      );
    } else {
      return (
        <button
          className="jumbotron__info__button__text"
          style={{ backgroundColor: this.props.accentColor }}
        >
          <h4>{this.renderButtonText(this.props)}</h4>
        </button>
      );
    }
  };
  // toggleModal = () => {
  //   console.log("from button modal", this.state.open);
  //   this.setState({
  //     open: !this.state.open,
  //   });
  // };
  render() {
    // const duration = 300;
    // const defaultStyle = {
    //   transition: `opacity ${duration}ms ease-in-out`,
    //   opacity: 0,
    // };

    // const transitionStyles = {
    //   entering: { opacity: 0 },
    //   entered: { opacity: 1 },
    //   exiting: { opacity: 0 },
    //   exited: { opacity: 0 },
    // };
    return (
      <div
        className="jumbotron__info__button"
        onClick={() => this.handleJumboButtonClick(this.props)}
      >
        {this.renderButton()}
      </div>
    );
  }
}
const mapStateToProps = () => ({});

export default connect(mapStateToProps, { addItemToCart })(JumbotronButton);
