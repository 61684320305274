import React, { Component } from "react";
import PropTypes from "prop-types";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import GroupCategory from "../groupCategory/groupCategory.js";
import redClose from "../../../../images/icons/close-red.svg";
import downExpandArrow from "../../../../images/icons/down-expand-arrow.svg";
import { union } from "lodash";
class FilterGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupOpen: false,
      subOpen: false,
      activeSub: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const notOpen = !prevState.groupOpen || !prevState.subOpen;
    const hasAFilter = nextProps.filter.length > 0;
    if (notOpen && hasAFilter) {
      // console.log(console.log("this is what I have", nextProps.group));
      let subOpen = false;
      let activeSub = [];
      const groupOpen = nextProps.group.categories.some((category) => {
        if (nextProps.filter.includes(category.name)) {
          return true;
        } else if (category.hasOwnProperty("subCategories")) {
          if (
            category.subCategories.some((sub) => nextProps.filter.includes(sub))
          ) {
            activeSub.push(category.name);
            subOpen = true;
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
      return {
        activeSub: union(activeSub, prevState.activeSub),
        groupOpen,
        subOpen,
      };
    } else {
      return null;
    }
  }

  renderCategories = (categories) => {
    return (
      <div className="category">
        <div
          className={`category__vertical ${
            this.state.groupOpen ? "purple" : ""
          }`}
          aria-hidden
        />
        <SlideDown closed={!this.state.groupOpen} className="parent-slidedown">
          {categories.map((i, index) => (
            <GroupCategory
              key={index}
              group={this.props.group.name}
              category={i}
              parentOpen={this.state.groupOpen}
              handleFilterItem={this.props.handleFilterItem}
              filter={this.props.filter}
              sorts={this.props.sorts}
              subShouldOpen={this.state.activeSub}
              toggleSub={this.toggleSub}
            />
          ))}
        </SlideDown>
      </div>
    );
  };
  toggleGroup = () => {
    //this block removes existing entries in the filter array when a category is collapsed
    this.props.group.categories.forEach((c) => {
      //checks if a category has subcategories
      if (c.subCategories.length > 1 && c.subCategories[0] !== "") {
        c.subCategories.forEach((s) => {
          //if the item exists in the filter array it removes it
          if (this.props.filter.indexOf(s) > -1) {
            this.props.handleFilterItem(s);
          }
        });
        //this is ran if a category has no sub category
      } else {
        if (this.props.filter.indexOf(c.name) > -1) {
          this.props.handleFilterItem(c.name);
        }
      }
    });
    // this needs to let the parent know that those filters are no longer active
    // set default to be new to old instead of old to new
    console.log(this.state.activeSub, "this is what we have in here");
    this.setState({
      groupOpen: !this.state.groupOpen,
      subOpen: false,
      activeSub: [],
    });
  };
  toggleSub = (name) => {
    const activeSub = [...this.state.activeSub];
    const containedIndex = activeSub.indexOf(name);
    if (containedIndex !== -1) {
      activeSub.splice(containedIndex, 1);
    } else {
      activeSub.push(name);
    }

    this.setState({ activeSub });
  };
  // handleClickEvent = () => {
  //   if(!this.state.groupOpen) {
  //     return ( this.toggleGroup )

  //   } else
  // }
  render() {
    return (
      <div className="filter__list__group">
        <div
          className="filter__list__group__title"
          onClick={!this.state.groupOpen ? this.toggleGroup : null}
        >
          <h4>{this.props.group.name}</h4>
          <div className="line-container">
            <div className="line-1"></div>
            <div className="line-2"></div>
          </div>

          {this.state.groupOpen ? (
            <img
              src={redClose}
              alt="red X close icon"
              onClick={this.toggleGroup}
            />
          ) : (
            <img src={downExpandArrow} alt="down arrow icon" />
          )}
        </div>

        {this.renderCategories(this.props.group.categories)}
      </div>
    );
  }
}

FilterGroup.propTypes = {
  group: PropTypes.object.isRequired,
  handleFilterItem: PropTypes.func.isRequired,
  filter: PropTypes.array.isRequired,
};

export default FilterGroup;
