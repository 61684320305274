import React, { Component } from "react";
import PropTypes from "prop-types";
import FilterGroup from "./filterGroup/filterGroup.js";
import Button from "../../utilities/buttons/button";

import "react-slidedown/lib/slidedown.css";

import "./itemGridFilter.sass";

class ItemGridFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  renderFilterList = (filter) => {
    return (
      <>
        {filter.map((i, index) => (
          <React.Fragment key={index}>
            <FilterGroup
              removeMultiple={this.props.removeMultiple}
              group={i}
              handleFilterItem={this.props.handleFilterItem}
              filter={this.props.filter}
              sorts={this.props.sorts}
            />
            {/* <h4>{i.name}</h4>
            {i.categories.length > 0 ? this.renderCategories(i.categories) : ""} */}
          </React.Fragment>
        ))}
        {this.props.mobile && (
          <Button
            type={"function"}
            style={"outline"}
            color={"purple"}
            text="View Results"
            function={this.props.toggleMobileFilter}
          />
        )}
      </>
    );
  };
  render() {
    return (
      <div className={`filter ${this.props.mobileExpanded ? "expanded" : ""}`}>
        {/* todo make a sick nasty search should be easy one liner */}
        <div className="filter__search">
          <h4>Filter Results</h4>
        </div>
        <div className="filter__list">
          {this.renderFilterList(this.props.filterList)}
        </div>
      </div>
    );
  }
}

ItemGridFilter.propTypes = {
  filter: PropTypes.array.isRequired,
  handleFilterItem: PropTypes.func.isRequired,
};

export default ItemGridFilter;
