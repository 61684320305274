import React, { Component } from "react";
import ImageAuto from "../utilities/imageAuto/imageAuto.js";
import ProjectStatusTag from "../utilities/projectStatusTag/projectStatusTag.js";
// import PopularProjectHeader from "./popularProjectHeader/popularProjectHeader.js";

import { Link } from "react-router-dom";
import arrowRightDark from "../../images/icons/arrow-right-dark.svg";
// import arrowRightAccent from "../../images/icons/arrow-right-accent.svg";
import "./popularProject.sass";
import { toUrlImg } from "../../helpers";

class PopularProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabletPhone: false,
    };
  }
  componentDidMount() {
    this.checkWindowSize();
    window.addEventListener("resize", this.checkWindowSize);
  }
  checkWindowSize = () => {
    if (window.innerWidth <= 650) {
      this.setState({
        tabletPhone: true,
      });
    } else {
      this.setState({
        tabletPhone: false,
      });
    }
  };
  render() {
    return (
      <div className="popular__project">
        {/* {!this.state.tabletPhone ? (
          <PopularProjectHeader arrowRightAccent={arrowRightAccent} />
        ) : (
          ""
        )} */}
        {/* commented this out for MVP */}
        {/* <PopularProjectHeader
          arrowRightAccent={arrowRightAccent}
          project={this.props.project}
        /> */}
        <Link to={`/projects/${this.props.project._id}`}>
          <h5>{this.props.project.name}</h5>
          <div className="popular__project__image">
            <ImageAuto
              image={toUrlImg(this.props.project.mainImage.square)}
              alt="keycaps"
            />
            <div className="popular__project__image__arrow">
              <img src={arrowRightDark} alt="arrow pointing right" />
            </div>
          </div>
          <div className="popular__project__info">
            {/* {this.state.tabletPhone ? (
            <PopularProjectHeader arrowRightAccent={arrowRightAccent} />
          ) : (
            ""
          )} */}

            <p className="popular__project__info__creator">
              {this.props.project.owner.userName}
            </p>
            <div className="popular__project__info__bottom">
              <p>{this.props.project.category}</p>
              <ProjectStatusTag
                projectStatus={this.props.project.projectFormat}
              />
            </div>
          </div>
          {/* <img src={this.props.image} alt="" /> */}
        </Link>
      </div>
    );
  }
}

export default PopularProject;
