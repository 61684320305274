import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getProjects } from "../../reducers/projects";
import { toUrlImg, truncateString, isTypeOfProject } from "../../helpers";
import { Link } from "react-router-dom";
import "./styles/projects.sass";
import Jumbotron from "../jumbotron/jumbotron";
import PopularProject from "../popularProject/popularProject.js";
import ItemGrid from "../itemGrid/itemGrid.js";
import Loading from "../utilities/loading/loading";
import { getConfig } from "../../reducers/siteConfig";
import { Helmet } from "react-helmet";
import { IProject } from "../../../server/models/projects";
import { ISiteConfig } from "../../../server/models/siteConfig";
import { StateType } from "../../reducers";
import { MatchType } from "../../App";
type ProjectsProps = {
  projects: IProject[];
  loading: boolean;
  err: any;
  message: string;
  config: ISiteConfig;
  configLoading: boolean;
  getConfig: (overRide: boolean) => void;
  getProjects: () => void;
  match: MatchType;
};
const Projects = (props: ProjectsProps) => {
  useEffect(() => {
    if (!props.configLoading && props.config === null) {
      props.getConfig(true);
    }
    if (!props.loading && props.projects.length === 0) {
      props.getProjects();
    }
  });
  if (
    props.loading === true ||
    props.configLoading === true ||
    props.projects.length === 0
  ) {
    return <Loading></Loading>;
  } else {
    return (
      <div className="projects page">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Boardsource - Projects</title>
          <meta
            name="description"
            content="View all projects - interest check and group buys on Boardsource."
          />
          <meta
            name="keywords"
            content="Boardsource, Keyboards, Boards, Custom Keyboards, ic, gb, keycaps"
          />
          <meta name="author" content="Boardsource" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <link rel="canonical" href={props.match.url} />

          <meta property="og:url" content={props.match.url} />

          <meta property="og:title" content="Boardsource - Projects" />
          <meta
            property="og:description"
            content="View all projects - interest check and group buys on Boardsource."
          />
          <meta
            property="og:image"
            content={toUrlImg(
              isTypeOfProject(props.config.projects.main)
                ? props.config.projects.main.mainImage.rectangle
                : ""
            )}
          />
        </Helmet>
        <Link
          to={`/projects/${
            isTypeOfProject(props.config.projects.main)
              ? props.config.projects.main._id
              : null
          }`}
        >
          <Jumbotron
            item={
              isTypeOfProject(props.config.projects.main)
                ? props.config.projects.main
                : null
            }
            name={
              isTypeOfProject(props.config.projects.main)
                ? props.config.projects.main.name
                : "missing"
            }
            // todo when posting this get the users user name and pump it into the owner field
            //RIGHT HERE FOR TODO
            // creator={this.props.project.owner.userName}
            creator={
              isTypeOfProject(props.config.projects.main)
                ? props.config.projects.main.owner.userName
                : "Boardsource"
            }
            kit={false}
            tagline={truncateString(
              isTypeOfProject(props.config.projects.main)
                ? props.config.projects.main.description
                : "",
              50
            )}
            accentColor={
              isTypeOfProject(props.config.projects.main)
                ? props.config.projects.main.projectColorFamily
                    .projectAccentColor
                : ""
            }
            image={
              isTypeOfProject(props.config.projects.main)
                ? props.config.projects.main.mainImage.rectangle
                : ""
            }
            altImages={
              isTypeOfProject(props.config.projects.main)
                ? props.config.projects.main.additionalImages
                : []
            }
            // projectType={this.state.projectType}
            productType={
              isTypeOfProject(props.config.projects.main)
                ? props.config.projects.main.type
                : "Group Buy"
            }
            projectStatus={
              isTypeOfProject(props.config.projects.main)
                ? props.config.projects.main.projectFormat
                : "Group Buy"
            }
            onClick={
              () => {}
              //props.history.push(
              //`/projects/${props.config.projects.main._id}`
              //)
            }
          />
        </Link>
        <h3 className="info-title top-space">Trending Projects</h3>
        <p className="info-text">
          Check out the community's most popular projects, updated regularly to
          show the most followed and viewed projects.
        </p>
        <div className="spacer content" />
        <div className="popular">
          <PopularProject project={props.config.projects.featured[0]} />
          {/* <PopularProject project={this.props.config.projects.featured[1]} /> */}
          {/* <PopularProject project={this.props.config.projects.featured[2]} /> */}
        </div>
        <h3 className="info-title top-space">All Boardsource Projects</h3>
        <div className="spacer content" />
        <ItemGrid items={props.projects} preSelected={""} />
      </div>
    );
  }
};

const mapStateToProps = (state: StateType) => {
  console.log("state", state);
  return {
    projects: state.project.projects,
    project: state.project.project,
    loading: state.project.loading,
    error: state.project.err,
    message: state.project.msg,
    config: state.config.config,
    configLoading: state.config.loading,
  };
};

export default connect(mapStateToProps, { getConfig, getProjects })(Projects);
