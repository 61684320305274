import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import arrowRightDark from "../../../images/icons/arrow-right-dark.svg";

import "./gridItemButton.sass";
import { returnItemStatus } from "../../../helpers";

class GridItemButton extends Component {
  constructor(props) {
    super(props);
  }
  //need to add links
  renderGridItemButton = (status) => {
    switch (status) {
      case "Group Buy":
        return <p>Configure</p>;

      case "Interest Check":
        return <p>Follow</p>;

      default:
        return <p>View</p>;
    }
  };
  buildLink = () => {
    switch (returnItemStatus(this.props.item)) {
      case "In Stock":
        return `store/${this.props.item._id}`;

      default:
        return `projects/${this.props.item._id}`;
    }
  };
  render() {
    return (
      <Link to={this.buildLink()}>
        <div className="grid__button">
          {this.renderGridItemButton(this.props.projectStatus)}
          <div className="grid__button__icon">
            <img src={arrowRightDark} alt="arrow pointing right" />
          </div>
        </div>
      </Link>
    );
  }
}

GridItemButton.propTypes = {
  projectStatus: PropTypes.string,
  kit: PropTypes.bool,
  link: PropTypes.string,
};

export default GridItemButton;
