import React, { Component } from "react";
import PropTypes from "prop-types";
import Imgix from "react-imgix";
import IdealImage from "react-ideal-image";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "./imageAuto.sass";

class ImageAuto extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false };
  }

  componentDidMount() {
    // console.log(this.props);
  }

  render() {
    // if you dont want this just swap these 2 lines and it will be exactly how you left it
    //haven't fixed the overall image issue yet but this does bring back loading issues, just fixing styling bugs first assuming i can get the other plugin to work
    if (this.props.image.startsWith("https://boardsource.imgix.net")) {
      // if (this.props.image.startsWith("https://boardsource.imgix.net") && false) {

      return (
        <div className="image">
          <div
            className="image-box"
            style={{
              height: !this.state.loaded ? this.props.width / 2 : "auto",
            }}
          >
            <Imgix
              className={`lazyload imgix ${
                !this.state.loaded ? "loading_image" : ""
              }`}
              src={this.props.image + "?q=80"}
              sizes={`${this.props.width}px`}
              width={this.props.width}
              alt={this.props.alt}
              // style={{ height: "auto" }}
              alt={this.props.altText}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              htmlAttributes={{
                onLoad: () => this.setState({ loaded: true }),
                src: this.props.image + "?q=1&fit=clip&w=300&h=300&auto=format",
                alt: this.props.alt,
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="image">
          <div className="autofake">
            <img
              src={this.props.image}
              alt={this.props.alt}
              style={{ height: "auto", maxWidth: this.props.width }}
            />
            {/*<IdealImage
            srcSet={[{ src: this.props.image, width: this.props.width }]}
            style={{ height: "auto" }}
            placeholder={{ color: "white" }}
            // theme={{ maxWidth: "100%" }}
            alt={this.props.alt}
          />*/}
          </div>
        </div>
      );
    }
  }
}

ImageAuto.defaultProps = {
  width: 1000,
  // alt: 'Alt text here'
};

ImageAuto.propTypes = {
  image: PropTypes.node.isRequired,
  width: PropTypes.number.isRequired,
  alt: PropTypes.string.isRequired,
};

export default ImageAuto;
