import React, { Component } from "react";
import PropTypes from "prop-types";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";

class GroupCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupCategoryOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.parentOpen !== this.props.parentOpen) {
      this.setState({
        groupCategoryOpen: false,
      });
    }
  }

  renderSubcategories = (category) => {
    return (
      <SlideDown
        className="child-slideodwn"
        closed={!this.props.subShouldOpen.includes(this.props.category.name)}
      >
        {category.map((c, i) => (
          <h6
            key={i}
            onClick={() => {
              this.props.handleFilterItem(
                this.props.group.startsWith("Sort") ? "sort." + c : c
              );
            }}
          >
            <div
              className={`active-dot small ${
                this.props.filter.indexOf(c) > -1 ||
                this.props.sorts.indexOf(c) > -1
                  ? "filled"
                  : ""
              }`}
            />
            {c}
          </h6>
        ))}
      </SlideDown>
    );
  };
  checkClickHandler = (subCategory) => {
    if (subCategory.length === 1 && subCategory[0] === "") {
      return this.props.handleFilterItem(this.props.category.name);
    }
  };
  render() {
    return (
      <>
        <h5
          onClick={() => {
            // this.setState(
            //   {
            //     groupCategoryOpen: !this.state.groupCategoryOpen,
            //   },
            //   this.checkClickHandler(this.props.category.subCategories)
            // )

            this.props.toggleSub(this.props.category.name);
            this.checkClickHandler(this.props.category.subCategories);
          }}
        >
          <div
            className={`active-dot large ${
              this.props.filter.indexOf(this.props.category.name) > -1
                ? "filled"
                : ""
            }`}
          />
          {this.props.category.name}
        </h5>
        {this.props.category.subCategories.length > 0 &&
        this.props.category.subCategories[0] !== ""
          ? this.renderSubcategories(this.props.category.subCategories)
          : ""}
      </>
    );
  }
}

GroupCategory.propTypes = {
  category: PropTypes.object.isRequired,
  handleFilterItem: PropTypes.func.isRequired,
  filter: PropTypes.array.isRequired,
};

export default GroupCategory;
