import React, { Component } from "react";
import PropTypes from "prop-types";

import "./imageInfoTag.sass";
import { Timestamp } from "mongodb";

var classNames = require("classnames");

class ImageInfoTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: "",
      color: ""
    };
  }
  componentWillMount() {
    if (this.props.size) {
      this.setState({ size: this.props.size });
    } else {
      this.setState({ size: "normal" });
    }
    if (this.props.color) {
      this.setState({ color: this.props.color });
    } else {
      this.setState({ color: "nofill" });
    }
  }
  returnTagString = type => {
    switch (type) {
      case "new":
        return "New";
        break;
      default:
        return "New";
        break;
    }
  };
  returnStyle = position => {
    // console.log(position);
    const styles = {
      bottom: "",
      top: "",
      left: "",
      right: ""
    };
    const styleKeyWords = position.split(" ");
    if (styleKeyWords.includes("bottom")) {
      styles.bottom = "0";
    } else {
      styles.top = "0";
    }
    if (styleKeyWords.includes("left")) {
      styles.left = "0";
    } else {
      styles.right = "0";
    }
    return styles;
  };
  render() {
    var tagClass = classNames({
      "image-info-tag": true,
      green: this.props.type === "new",
      normal: this.state.size === "normal",
      dark: this.state.color === "dark",
      light: this.state.color === "light",
      nofill: this.state.color === "none"
    });
    return (
      <div className={tagClass} style={this.returnStyle(this.props.position)}>
        <p>{this.returnTagString(this.props.type)}</p>
      </div>
    );
  }
}

ImageInfoTag.propTypes = {
  type: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired
};

//position is expecting "vert (bottom or top) horz (left or right)" i.e. 'bottom left'

export default ImageInfoTag;
