import React, { Component } from "react";
// import IdealImage from "react-ideal-image";
import {
  CarouselProvider,
  Slider,
  Slide,
  Image,
  ImageWithZoom,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import arrowBack from "../../../images/icons/arrow-left.svg";
import arrowForward from "../../../images/icons/arrow-right.svg";

import "./jumbotronImage.sass";
import { toUrlImg } from "../../../helpers";

class JumbotronImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  filterImages = () => {
    const filteredImgs = this.props.altImages
      .filter((img) => img.type === "rectangle")
      .map((img) => img.image);
    const imgsToSlice = filteredImgs.length > 2 ? 2 : filteredImgs.length;
    return [this.props.image, ...filteredImgs.slice(0, imgsToSlice)];
  };
  render() {
    const images = this.filterImages();
    return (
      <div className="jumbotron__image__container">
        <CarouselProvider
          visibleSlides={1}
          //needs to be dynamic
          totalSlides={images.length}
          naturalSlideHeight={375}
          naturalSlideWidth={700}
          infinite={true}
          className={"jumbotron__image__container__carousel"}
        >
          <Slider className="jumbotron__image__container__carousel__slider">
            {images.map((image, index) => (
              <Slide index={index} key={index}>
                <Image src={toUrlImg(image)} />
              </Slide>
            ))}
          </Slider>
          {images.length > 1 ? (
            <div className="jumbotron__image__container__carousel__controls">
              <ButtonBack>
                <div
                  className="jumbotron__image__container__carousel__controls__icon"
                  style={{ backgroundColor: this.props.accentColor }}
                >
                  <img
                    className="jumbotron--arrow"
                    src={arrowBack}
                    alt="back arrow"
                  />
                </div>
              </ButtonBack>
              <ButtonNext>
                <div
                  className="jumbotron__image__container__carousel__controls__icon"
                  style={{ backgroundColor: this.props.accentColor }}
                >
                  <img
                    className="jumbotron--arrow"
                    src={arrowForward}
                    alt="forward arrow"
                  />
                </div>
              </ButtonNext>
            </div>
          ) : (
            ""
          )}
        </CarouselProvider>
      </div>
    );
  }
}

export default JumbotronImage;

{
  /* <IdealImage
          srcSet={[{ src: this.props.image, width: 1000 }]}
          alt="hey whats up"
          style={{ height: "100%" }}
          placeholder={{ color: "red" }}
          theme={{ maxWidth: "100%" }}
          alt={`${this.props.title} ${this.props.productType}`}
        /> */
}
