import React, { Component } from "react";
import PropTypes from "prop-types";

import "./clickImage.sass";

import rightArrow from "../../../images/icons/arrow-right-dark.svg";

class ClickImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      //need an onclick here
      <div className="click-wrapper">
        {this.props.children}
        <div className="click-wrapper__arrow">
          <img src={rightArrow} alt="arrow pointing right" />
        </div>
      </div>
    );
  }
}

ClickImage.propTypes = {
  link: PropTypes.string,
};

export default ClickImage;
