import React, { Component } from "react";
import PropTypes from "prop-types";

import "./projectStatusTag.sass";

var classNames = require("classnames");

class ProjectStatusTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      small: false,
    };
  }
  componentDidMount() {
    if (this.props.small) {
      this.setState({ small: true });
    }
    this.setTagSize(window.innerWidth);
    window.addEventListener("resize", () => this.setTagSize(window.innerWidth));
  }
  setTagSize = (width) => {
    if (width < 950) {
      this.setState({ small: true });
    } else if (width > 950) {
      this.setState({ small: false });
    } else {
      return;
    }
  };

  render() {
    var statusTagClass = classNames({
      small: this.state.small,
      small: this.props.small,
      "group-buy": this.props.projectStatus === "Group Buy",
      "interest-check": this.props.projectStatus === "Interest Check",
      "in-stock": this.props.projectStatus === "In Stock",
      "on-the-way": this.props.projectStatus === "More On The Way",
      "out-of-stock": this.props.projectStatus === "Out Of Stock",
      help: this.props.projectStatus === "Help",
      guide: this.props.projectStatus === "Build Guide",
      update: this.props.projectStatus === "Update",
    });
    return (
      <div className={`status__tag ${statusTagClass}`}>
        {this.props.projectStatus}
      </div>
    );
  }
}

ProjectStatusTag.propTypes = {
  projectStatus: PropTypes.string.isRequired,
};

export default ProjectStatusTag;
