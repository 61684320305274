import React, { Component } from "react";
import ItemGridFilter from "./itemGridFilter/itemGridFilter.js";
import Grid from "./grid/grid.js";
import { union } from "lodash";
import "./itemGrid.sass";

class ItemGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridProjects: [],
      filterList: [
        {
          name: "Sort By",
          categories: [
            { name: "Date Added", subCategories: ["New to Old", "Old to New"] },
          ],
        },
        {
          name: "Product",
          categories: [
            {
              name: "Cases",
              subCategories: [
                "Full Size",
                "TKL",
                "70%",
                "60%",
                "50%",
                "40%",
                "Sub 30%",
              ],
            },
            {
              name: "Keycap Sets",
              subCategories: [
                "SA",
                "GMK",
                "XDA",
                "DSA",
                "KAT",
                "Low-Pro",
                "Other",
              ],
            },
            { name: "Artisan Keycaps", subCategories: [""] },
            {
              name: "Misc. / Accessories",
              subCategories: ["Deskmats", "Cables", "Macro Pads"],
            },
          ],
        },
        {
          name: "Materials",
          categories: [
            { name: "Alumium", subCategories: [""] },
            { name: "Acryllic", subCategories: [""] },
            { name: "pcb", subCategories: [""] },
            { name: "Other", subCategories: [""] },
          ],
        },
        {
          name: "Colors",
          categories: [
            { name: "Dark", subCategories: [""] },
            { name: "Light", subCategories: [""] },
            { name: "Colorful", subCategories: [""] },
            {
              name: "Specific",
              subCategories: [
                "Black",
                "Grey",
                "Silver",
                "White",
                "Red",
                "Orange",
                "Yellow",
                "Green",
                "Blue",
                "Purple",
                "Pink",
              ],
            },
          ],
        },
      ],
      sorts: "New to Old",
      filter: [],
      mobileFilterOpen: false,
      gridScrollTop: null,
      mobileFilterLeft: 0,
      mobileFilterFixed: false,
    };
    this.mobileFilter = React.createRef();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.preSelected) {
      const search = new URLSearchParams(nextProps.preSelected).getAll(
        "search"
      );
      return { filter: union(search, prevState.filter) };
    } else {
      return null;
    }
  }
  componentDidMount() {
    if (!this.props.isStore) {
      this.setState({
        filterList: this.state.filterList.map((filterObj) => {
          if (filterObj.name === "Sort By") {
            return {
              ...filterObj,
              categories: [
                ...filterObj.categories,
                { name: "Followers", subCategories: ["Most", "Least"] },
              ],
            };
          } else {
            return filterObj;
          }
        }),
      });
    }
    window.addEventListener("scroll", () => this.styleMobileFilterPosition());
  }
  styleMobileFilterPosition = () => {
    if (this.mobileFilter && this.mobileFilter.current !== null) {
      const el = this.mobileFilter;
      const heightLimit = el.current.parentElement.parentNode.offsetTop;
      // console.log(heightLimit);
      this.setState({
        mobileFilterLeft: el.current.parentElement.offsetParent.offsetLeft,
      });
      if (window.pageYOffset >= heightLimit - 150) {
        this.setState({ mobileFilterFixed: true });
        el.current.classList.add("fixed");
      } else if (window.pageYOffset < heightLimit - 150) {
        this.setState({ mobileFilterFixed: false });
        el.current.classList.remove("fixed");
      }
    }
  };
  handleFilterItem = (item) => {
    if (item.startsWith("sort")) {
      const sort = item.split(".")[1];
      let sorts = this.state.sorts;
      if (sorts === sort) {
        this.setState({
          sorts: "",
        });
      } else {
        this.setState({
          sorts: sort,
        });
      }
    } else {
      let filterArray = [...this.state.filter];
      const index = filterArray.indexOf(item);
      if (index > -1) {
        filterArray.splice(index, 1);
        this.setState({
          filter: filterArray,
        });
      } else {
        filterArray.push(item);
        this.setState({
          filter: filterArray,
        });
      }
    }
  };
  removeMultiple = (arrToRemove) => {
    const filter = this.state.filter.filter((f) => !arrToRemove.includes(f));
    this.setState({ filter });
  };
  toggleMobileFilter = () => {
    this.setState({ mobileFilterOpen: !this.state.mobileFilterOpen });
    // process.nextTick(() => console.log(this.state.mobileFilterOpen));
  };
  setTop = (gridScrollTop) => {
    this.setState({ gridScrollTop });
    // process.nextTick(() =>
    //    console.log("item grid state: ", this.state.gridScrollTop)
    // );
    // console.log(gridScrollTop);
  };
  render() {
    return (
      <div className="item__grid">
        <div className="item__grid__filter">
          {/* <ItemGridFilter
            filterList={this.state.filterList}
            handleFilterItem={this.handleFilterItem}
            filter={this.state.filter}
            sorts={this.state.sorts}
          /> */}
          <div
            className={`item__grid__filter--mobile`}
            ref={this.mobileFilter}
            style={
              this.state.mobileFilterFixed
                ? { left: this.state.mobileFilterLeft, top: "110px" }
                : {}
            }
          >
            <div
              className="item__grid__filter--mobile__toggle"
              onClick={() => this.toggleMobileFilter()}
            >
              {this.state.mobileFilterOpen ? (
                <h5>Close To View Results</h5>
              ) : (
                <h5>Click to Expand Filter</h5>
              )}
              {/* <h5>Click to Expand Filter</h5> */}
            </div>
            <span className={`mobile-filter`}>
              <ItemGridFilter
                removeMultiple={this.removeMultiple}
                filterList={this.state.filterList}
                handleFilterItem={this.handleFilterItem}
                filter={this.state.filter}
                sorts={this.state.sorts}
                mobile
                mobileExpanded={this.state.mobileFilterOpen}
                toggleMobileFilter={this.toggleMobileFilter}
              />
            </span>
          </div>
          <div className="item__grid__filter--desktop">
            <ItemGridFilter
              removeMultiple={this.removeMultiple}
              filterList={this.state.filterList}
              handleFilterItem={this.handleFilterItem}
              filter={this.state.filter}
              sorts={this.state.sorts}
            />
          </div>
        </div>
        {/* <div className="divider" /> */}
        <div className="item__grid__list">
          <Grid
            items={this.props.items}
            sorts={this.state.sorts}
            filter={this.state.filter}
            setTop={this.setTop}
            isStore={this.props.isStore}
          />
        </div>
      </div>
    );
  }
}

export default ItemGrid;
